
h1 {
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  color: rgb(60, 64, 67);
}

main {
  margin: 80px;
}

.list {
  display: flex; 
  margin: auto;
  padding: 0;
  flex-wrap: wrap;
}

.list > div {
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-bottom: 8px;
  border: 1px solid #dadce0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin: 8px;
  flex-grow: 0;
}

.list > :hover {
  background-color: rgba(0, 0, 0, 0.025);
}

.list  > div > header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 232px;
  height: 132px;
}

.list > div > h2 {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  white-space: nowrap;
  color: rgb(32, 33, 36);
  margin: 0;
  margin-left: 16px;
  margin-right: 16px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}